export function isObject(o) {
    return o instanceof Object && o.constructor === Object;
}

export function getPath(o) {
    if (!o) return null;

    let p = o['_path'];

    if (typeof p === 'string') {
        p = p
            .split('/')
            .pop()
            .replace(/-./g, (x) => x[1].toUpperCase());
    }

    return p;
}

export function deepCompare(obj1, obj2, stack: string[] = [], errors = []) {
    for (const k in obj1) {
        if (Object.prototype.hasOwnProperty.call(obj2, k) && obj2[k]) {
            if (typeof obj1[k] == 'object') {
                deepCompare(obj1[k], obj2[k], [...stack, k], errors);
            } else {
                if (obj1[k] != obj2[k]) {
                    const msg = 'different values at ' + obj1['_path'];
                    if (!errors.includes(msg)) {
                        errors.push(msg);
                    }
                }
            }
        } else {
            errors.push(
                'ERROR value "' +
                    (isObject(obj1[k]) ? transform(obj1[k], []) : obj1[k]) +
                    '" does not exist in ' +
                    obj1['_path'],
            );
        }
    }
    return errors;
}

export const importantKeys = [
    'heading',
    'labelText',
    'subheading',
    'html',
    'labelValue',
    'keyCode',
    'hintText'
];

// object with path -> extract path and traverse value
// object without path -> use it as structural thing
// array - for each of members treat them as object to traverse
// preserve simple value if key is important
// drop simple value otherwise

// carry a path

export function transform(
    data: Record<any, any>,
    path: string[],
    includePath = false,
): Record<any, any> {
    const newObj = {};

    if (includePath && data['_path']) {
        newObj['_path'] = data['_path'];
    }

    for (const k in data) {
        if (Array.isArray(data[k])) {
            for (let i = 0; i < data[k].length; i++) {
                const item = data[k][i];

                const p = getPath(item);

                const traverseResult = transform(item, path.concat(k + '[' + i + ']'), includePath);

                if (!traverseResult) continue;

                if (p) {
                    newObj[p] = traverseResult;
                } else {
                    newObj['text' + (i + 1)] = traverseResult;
                }
            }
        }

        if (isObject(data[k])) {
            const p = getPath(data[k]);

            const traverseResult = transform(data[k], path.concat(k), includePath);

            if (!traverseResult) continue;

            // if it has path
            if (p) {
                newObj[p] = traverseResult;
            } else {
                newObj[k] = traverseResult;
            }
        }

        if (importantKeys.includes(k) && data[k]) {
            newObj[k] = data[k];
        }
    }

    if (Object.keys(newObj).length === 1) {
        return Object.values(newObj)[0];
    }

    if (Object.keys(newObj).length === 0) {
        return null;
    }

    return newObj;
}
