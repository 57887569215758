<zci-step-wrapper
    [title]="content.addUserFlow.step1.pageTitle"
    [nextButtonLabelText]="content.stepper.nextButtonLabelText"
    (clickNextButton)="onNextStepLoad()"
    (clickBackButton)="onBack()"
    (clickContactUs)="onContactUs(location)"
    (clickQuit)="onQuit(location)"
    [totalSteps]="content.addUserFlow.stepperTitles"
    [currentStep]="currentStep"
    [showNextButton]="false">
    <div class="justify-content-center negative-margin">
        <zci-triage-tile
            [title]="content.addUserFlow.step1.existingGrantee.title"
            (click)="navigateSelectUser()">
        </zci-triage-tile>
        <zci-triage-tile
            [title]="content.addUserFlow.step1.newGrantee.title"
            [description]="content.addUserFlow.step1.newGrantee.description"
            (click)="navigateAddSomeoneElse()">
        </zci-triage-tile>
        <zci-triage-tile
            [title]="content.addUserFlow.step1.nonVgGrantee.title"
            [description]="content.addUserFlow.step1.nonVgGrantee.description"
            (click)="navigateToFormFromNonVGUser()">
        </zci-triage-tile>
        <zci-triage-tile
            [title]="content.addUserFlow.step1.orgTrust.title"
            [description]="content.addUserFlow.step1.orgTrust.description"
            (click)="navigateToFormFromOrgTrust()">
        </zci-triage-tile>
    </div>
</zci-step-wrapper>
