export const ACCOUNT_ID_PARAM = 'id';
export const TOKEN_PARAM = 'token';

export const APPLICATION_ROUTES = {
    HOME: '',
    USERS: 'users',
    OWN_ACCOUNTS: 'my-accounts',
    OBSERVABLE_ACCOUNTS: 'other-accounts',
    ABOUT: 'about',
    TECH_DIFF_REDIRECT: 'redirect',
    TECHNICAL_DIFFICULTIES: 'technical-difficulties',
    INELIGIBLE: {
        FULL_PATH: 'ineligible/:id',
        PATH: 'ineligible',
    },
    USERS_ON_ACCOUNT: {
        FULL_PATH: 'my-accounts/users-on-account/:' + ACCOUNT_ID_PARAM,
        CHILD_PATH: 'users-on-account/:' + ACCOUNT_ID_PARAM,
    },
    EDIT_USERS: {
        FULL_PATH: 'my-accounts/edit-users',
        CHILD_PATH: 'edit-users',
    },
    REMOVE_USERS: {
        FULL_PATH: 'my-accounts/:' + ACCOUNT_ID_PARAM + '/remove-users',
        CHILD_PATH: ':' + ACCOUNT_ID_PARAM + 'remove-users',
    },
    ADD_EXISTING_USER: {
        FULL_PATH: 'users/add-existing-user',
        CHILD_PATH: 'add-existing-user',
    },
    EDIT_USER_ACCESS: {
        FULL_PATH: 'users/edit-user-access',
        CHILD_PATH: 'edit-user-access',
    },
    REMOVE_USER_ACCESS: {
        FULL_PATH: 'users/remove-user-access',
        CHILD_PATH: 'remove-user-access',
    },
    REMOVE_ACCESS_FROM_ACCOUNT: {
        FULL_PATH: 'other-accounts/remove-access_from_account',
        CHILD_PATH: 'remove-access_from_account',
    },
    ADD_USER_TO_ACCOUNT: {
        FULL_PATH: 'my-accounts/:' + ACCOUNT_ID_PARAM + '/add-users',
        CHILD_PATH: ':' + ACCOUNT_ID_PARAM + 'add-users',
    },

    GRANTEE_PIN_INPUT: 'authenticate-pin/:' + TOKEN_PARAM,

    GENERIC_ERROR: 'error',

    //TODO: append case reference number to below path
    REGULATORY_QUESTIONS: 'regulatory-questions',
};
