import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, take } from 'rxjs';
import { ineligibleScenariosForGranteeInGranteeFlow } from '../store/actions/ineligible-scenarios.action';
import { select, Store } from '@ngrx/store';
import { selectGranteeIneligibleReason } from '../store/selectors/grantee.selector';

@Injectable({
    providedIn: 'root',
})
export class IneligibleScenariosResolver implements Resolve<any> {
    constructor(private store: Store) {}

    resolve(route: ActivatedRouteSnapshot,
            state: RouterStateSnapshot): Observable<any> {
        this.store.dispatch(ineligibleScenariosForGranteeInGranteeFlow());
        return this.store.pipe(
            select(selectGranteeIneligibleReason),
            take(1),
        )
    }
}
