<div class="col-12 col-sm-9 col-md-9 col-lg-8 col-xl-8 col-xxl-8">
    <p class="c11n-text-xl-headline c11n-space-stack-2x">
        {{ granteeRegulatoryQuestionContent.heading }}
    </p>
    <p class="c11n-text-md c11n-space-stack-6x">
        {{ granteeRegulatoryQuestionContent.body }}
        <br />
        <a c11n-link [variant]="'primary-reinforced'" (click)="toggleDrawer()"
            >{{ granteeRegulatoryQuestionContent.navFaq }}
        </a>
    </p>

    <c11n-radio-group
        [legendText]="granteeRegulatoryQuestionContent.frmfYouOrSpouseVgEmployee.heading"
        [errorText]="
            granteeRegulatoryQuestionContent.frmfYouOrSpouseVgEmployee.errPleaseChooseAResponse
        "
        [hasError]="vanguardEmployee.touched && vanguardEmployee.invalid"
        [variant]="'tiles'"
        [formGroup]="controlQuestionFormGroup"
    >
        <c11n-radio
            [focusLabel]="false"
            [labelText]="granteeRegulatoryQuestionContent.frmfYouOrSpouseVgEmployee.frmfYes"
        >
            <input
                c11nRadioInput
                type="radio"
                [name]="vanguardEmployeeControlName"
                value="1"
                [formControlName]="vanguardEmployeeControlName"
            />
        </c11n-radio>
        <c11n-radio
            [focusLabel]="false"
            [labelText]="granteeRegulatoryQuestionContent.frmfYouOrSpouseVgEmployee.frmfNo"
        >
            <input
                c11nRadioInput
                type="radio"
                [name]="vanguardEmployeeControlName"
                value="2"
                [formControlName]="vanguardEmployeeControlName"
            />
        </c11n-radio>
    </c11n-radio-group>

    <div class="c11n-space-stack-4x"></div>

    <c11n-radio-group
        [legendText]="granteeRegulatoryQuestionContent.frmfMemberOfStockXchange.heading"
        [errorText]="
            granteeRegulatoryQuestionContent.frmfMemberOfStockXchange.errPleaseChooseAResponse
        "
        [hasError]="associatedPerson.touched && associatedPerson.invalid"
        [variant]="'tiles'"
        [formGroup]="controlQuestionFormGroup"
    >
        <c11n-radio
            [focusLabel]="false"
            [labelText]="granteeRegulatoryQuestionContent.frmfMemberOfStockXchange.frmfYes"
        >
            <input
                c11nRadioInput
                type="radio"
                [name]="associatedPersonControlName"
                value="1"
                [formControlName]="associatedPersonControlName"
            />
        </c11n-radio>
        <c11n-radio
            [focusLabel]="false"
            [labelText]="granteeRegulatoryQuestionContent.frmfMemberOfStockXchange.frmfNo"
        >
            <input
                c11nRadioInput
                type="radio"
                [name]="associatedPersonControlName"
                value="2"
                [formControlName]="associatedPersonControlName"
            />
        </c11n-radio>
    </c11n-radio-group>

    <div class="c11n-space-stack-4x"></div>

    <c11n-radio-group
        [legendText]="granteeRegulatoryQuestionContent.frmfMemberPerSecRule144.heading"
        [errorText]="
            granteeRegulatoryQuestionContent.frmfMemberPerSecRule144.errPleaseChooseAResponse
        "
        [hasError]="controlPerson.touched && controlPerson.invalid"
        [variant]="'tiles'"
        [formGroup]="controlQuestionFormGroup"
    >
        <c11n-radio
            [focusLabel]="false"
            [labelText]="granteeRegulatoryQuestionContent.frmfMemberPerSecRule144.frmfYes"
            [labelWeight]="'normal'"
        >
            <input
                c11nRadioInput
                type="radio"
                [name]="controlPersonControlName"
                value="1"
                [formControlName]="controlPersonControlName"
            />
        </c11n-radio>
        <c11n-radio
            [focusLabel]="false"
            [labelText]="granteeRegulatoryQuestionContent.frmfMemberPerSecRule144.frmfNo"
            [labelWeight]="'normal'"
        >
            <input
                c11nRadioInput
                type="radio"
                [name]="controlPersonControlName"
                value="2"
                [formControlName]="controlPersonControlName"
            />
        </c11n-radio>
    </c11n-radio-group>
    <div class="c11n-space-stack-10x"></div>
    <c11n-modal-dialog-entrypoint></c11n-modal-dialog-entrypoint>
    <c11n-modal-dialog
        [variant]="'basic'"
        [primaryButtonLabel]="granteeRegulatoryForeignAddressModalContent.navContinue"
        [secondaryButtonLabel]="granteeRegulatoryForeignAddressModalContent.navBack"
        [headingText]="granteeRegulatoryForeignAddressModalContent.heading"
        [hideBody]="true"
        [maxWidth]="660"
        [useContentWidth]="false"
        (primaryClick)="navigateToForm()"
        #granteeRegulatoryForeignAddressModal
    >
    </c11n-modal-dialog>
</div>
<aside
    c11n-drawer
    class="control-questions-drawer"
    [isModal]="isTabletView$ | async"
    [drawerWidth]="(isMobileView$ | async) ? '100%' : '480px'"
    (drawerState)="onDrawerStateChange($event)"
    #controlQuestionsDrawer
    data-cy="control-questions-drawer"
>
    <div>
        <div class="faq-title">
            <h2 class="c11n-text-xl-headline c11n-space-stack-2x">
                {{ granteeFAQDrawerContent.heading }}
            </h2>
        </div>
        <div class="associated-title">
            <h2 class="c11n-text-lg-headline c11n-space-stack-2x">
                {{ granteeFAQDrawerContent.faqWhatIsAssociatedPerson.heading }}
            </h2>
        </div>
        <div class="associated-body">
            <p
                class="c11n-text-md c11n-space-stack-2x"
                [innerHTML]="granteeFAQDrawerContent.faqWhatIsAssociatedPerson.text1"
            ></p>
        </div>
        <div class="control-person-title">
            <h2 class="c11n-text-lg-headline c11n-space-stack-2x">
                {{ granteeFAQDrawerContent.faqWhatIsControlPerson.heading }}
            </h2>
        </div>
        <div class="control-person-body-1">
            <p
                class="c11n-text-md c11n-space-stack-2x"
                [innerHTML]="granteeFAQDrawerContent.faqWhatIsControlPerson.text1"
            ></p>
        </div>
    </div>
</aside>
