<p class="c11n-text-xl-headline c11n-space-stack-6x">{{ content?.addUserFlow.mvp.reviewAndSubmit.title }}</p>
<div class="review-container" data-cy="review-and-submit-container">
    <p class="c11n-text-md-bold c11n-space-stack-4x">
        {{
            content?.addUserFlow.mvp.reviewAndSubmit.description
                | replaceParam
                : [
                    ['userName', userName$ | async],
                    ['accessLevelAccess', accessLevel$ | async | lowercase]
                ]
        }}
    </p>
    <ul role="list" class="review-container__account-list">
        <li>{{ accountName$ | async }}</li>
    </ul>
</div>

<div *ngIf="hasCoOwner$| async" data-cy="review-and-submit-note">
    <span class="c11n-text-md-bold">{{ content.addUserFlow.step5.note }}</span>
    <span class="c11n-text-md">{{
            content?.addUserFlow.mvp.reviewAndSubmit.noteDescriptionPartOne
                | replaceParam
                : [
                    ['firstName', firstName$ | async],
                    ['firstName', firstName$ | async]
                ]
        }}</span>
    <span class="c11n-text-md-bold">{{
            content.addUserFlow.mvp.reviewAndSubmit.noteDescriptionPartTwo
        }}</span>
    <span class="c11n-text-md">{{ content.addUserFlow.mvp.reviewAndSubmit.noteDescriptionPartThree }}</span>
</div>
