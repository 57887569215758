<div class="col-12 col-sm-11 col-md-11 col-lg-9 col-xl-9 col-xxl-9">
    <c11n-modal-dialog
        [variant]="'warning'"
        [headingText]="headingText"
        [primaryButtonLabel]="content?.addUserFlow.mvp.accessLevel.modal.primaryButtonText"
        [secondaryButtonLabel]="content?.addUserFlow.mvp.accessLevel.modal.secondaryButtonText"
        (primaryClick)="onContinueModal()"
        [maxWidth]="660"
        [useContentWidth]="false"
        #selectAccessModalDialogue>
        <div c11n-modal-dialog-body>
            <p class="c11n-text-md c11n-space-stack-2x" data-cy="accessModal">
                {{ bodyText }}
            </p>
        </div>
    </c11n-modal-dialog>
    <c11n-modal-dialog
        [variant]="'warning'"
        [headingText]="content?.addUserFlow.mvp.accessLevel.cashPlusModal.headingText"
        [primaryButtonLabel]="content?.addUserFlow.mvp.accessLevel.modal.primaryButtonText"
        [secondaryButtonLabel]="content?.addUserFlow.mvp.accessLevel.modal.secondaryButtonText"
        (primaryClick)="onContinueModal()"
        [maxWidth]="660"
        [useContentWidth]="false"
        #cashPlusModalDialogue>
        <div c11n-modal-dialog-body>
            <p class="c11n-text-md c11n-space-stack-2x" data-cy="accessModal">
                {{ content?.addUserFlow.mvp.accessLevel.cashPlusModal.bodyText }}
            </p>
        </div>
    </c11n-modal-dialog>
    <p class="c11n-text-xl-headline c11n-space-stack-6x">{{ content?.addUserFlow.mvp.accessLevel.title }}</p>
    <zci-access-level-tile
        [data]="content?.addUserFlow.mvp.accessLevel.infoOnly"
        (click)="selectInfoOnlyAndNavigate()">
    >
    </zci-access-level-tile>
    <zci-access-level-tile
        [data]="content?.addUserFlow.mvp.accessLevel.limited"
        (click)="selectLimitedAndNavigate()">
    >
    </zci-access-level-tile>
    <zci-access-level-tile
        [data]="content?.addUserFlow.mvp.accessLevel.fullAuthority"
        (click)="navigateToForm()">
    >
    </zci-access-level-tile>
</div>



