<p class="c11n-text-xl-headline c11n-space-stack-3x">
    {{ content?.addUserFlow.mvp.selectUser.tileTitle }}
</p>
<c11n-modal-dialog
    [variant]="'basic'"
    [primaryButtonLabel]="content?.selectUserLevelModalDialog.primaryButtonText"
    [secondaryButtonLabel]="content?.selectUserLevelModalDialog.secondaryButtonText"
    [headingText]="content?.selectUserLevelModalDialog.body"
    [hideBody]="true"
    (primaryClick)="navigateToForm()"
    [maxWidth]="660"
    [useContentWidth]="false"
    #selectUserLevelModalDialog
    data-cy="">
</c11n-modal-dialog>
<c11n-banner
    role="alert"
    class="section-width c11n-space-stack-3x"
    [size]="'small'"
    [variant]="'error'"
    [hasCloseControl]="false"
    *ngIf="selectedUserFormControl.invalid && selectedUserFormControl.dirty"
    data-cy="foreign-address-error-banner">
    <div
        class="c11n-text-md--crop"
        *ngIf="selectedUserFormControl.invalid && selectedUserFormControl.dirty">
        {{ content?.addUserFlow.mvp.selectUser.validationMessage.required }}
    </div>
</c11n-banner>
<div class="section-width c11n-space-stack-6x">
    <c11n-radio-group
        [groupId]="radioGroupId"
        [hideLegend]="true"
        [legendText]="content?.addUserFlow.mvp.selectUser.tileTitle"
        [formGroup]="usrFormGroup"
        [variant]="'tiles'"
        >
        <c11n-radio
            *ngFor="let usr of usrs$ | async; index as i"
            [focusLabel]="true"
            [labelText]="constructUserName(usr)">
            <input
                c11nRadioInput
                type="radio"
                [name]="selectedUserFormControlName"
                [value]="usr"
                [formControlName]="selectedUserFormControlName" />
        </c11n-radio>
    </c11n-radio-group>
    <button
        c11n-link
        class="c11n-text-md pointer add-someone-else-button"
        iconPosition="leading"
        variant="primary-independent"
        iconName="plus"
        (click)="onAddSomeoneElse()"
        data-cy="stepper-add-someone-else-button">
        {{ content?.addUserFlow.mvp.selectUser.addSomeoneElseLink }}
    </button>
</div>
<zci-gaf-complete-our-form-mvp></zci-gaf-complete-our-form-mvp>
