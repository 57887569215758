<p class="c11n-text-xl-headline c11n-space-stack-2x">{{ content?.addUserFlow.mvp.selectUser.addSomeoneElseForm.title }}</p>
<c11n-banner
    role="alert"
    class="section-width c11n-space-stack-3x"
    [size]="'small'"
    [variant]="'error'"
    [hasCloseControl]="false"
    *ngIf="isTherePendingRequest"
    data-cy="pending-request-banner">
    <div
        class="c11n-text-md--crop">
        <div>{{ content?.addUserFlow.mvp.selectUser.addSomeoneElsePendingRequestBanner.text1 }}</div>
        <div>{{ content?.addUserFlow.mvp.selectUser.addSomeoneElsePendingRequestBanner.text2 }}</div>
    </div>
</c11n-banner>
<form [formGroup]="personalInfoForm" class="form">
    <fieldset>
        <legend class="c11n-space-stack-6x">
            {{ content?.addUserFlow.mvp.selectUser.addSomeoneElseForm.description }}
        </legend>
        <div class="c11n-space-stack-4x">
            <label c11n-label [for]="firstNameControlName" size="medium">{{
                    content?.addUserFlow.mvp.selectUser.addSomeoneElseForm.firstNameLabel
                }}</label>
            <c11n-input
                size="medium"
                [hasError]="firstNameFormControl.invalid && firstNameFormControl.touched">
                <input
                    c11nInput
                    type="text"
                    id="firstName"
                    name="firstName"
                    class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8"
                    [formControlName]="firstNameControlName"/>
            </c11n-input>
            <c11n-hint-error
                [hasError]="
                        firstNameFormControl.hasError('required') && firstNameFormControl.touched
                    "
                [errorText]="
                        content?.addUserFlow.mvp.selectUser.addSomeoneElseForm.firstNameRequiredError
                    "
                size="medium"
            ></c11n-hint-error>
            <c11n-hint-error
                [hasError]="
                        firstNameFormControl.hasError('pattern') && firstNameFormControl.touched
                    "
                [errorText]="
                        content?.addUserFlow.mvp.selectUser.addSomeoneElseForm.incorrectNameError
                    "
                size="medium"
            ></c11n-hint-error>
        </div>

        <div class="c11n-space-stack-4x">
            <label c11n-label [for]="lastNameControlName" size="medium">{{
                    content?.addUserFlow.mvp.selectUser.addSomeoneElseForm.lastNameLabel
                }}</label>
            <c11n-input
                size="medium"
                [hasError]="lastNameFormControl.invalid && lastNameFormControl.touched">
                <input
                    c11nInput
                    type="text"
                    id="lastName"
                    name="lastName"
                    class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8"
                    [formControlName]="lastNameControlName"/>
            </c11n-input>
            <c11n-hint-error
                [hasError]="
                        lastNameFormControl.hasError('required') && lastNameFormControl.touched
                    "
                [errorText]="content?.addUserFlow.mvp.selectUser.addSomeoneElseForm.lastNameRequiredError"
                size="medium"
            ></c11n-hint-error>
            <c11n-hint-error
                [hasError]="
                        lastNameFormControl.hasError('pattern') && lastNameFormControl.touched
                    "
                [errorText]="content?.addUserFlow.mvp.selectUser.addSomeoneElseForm.incorrectNameError"
                size="medium"
            ></c11n-hint-error>
        </div>

        <div class="c11n-space-stack-4x">
            <label c11n-label [for]="emailControlName" size="medium">{{
                    content?.addUserFlow.mvp.selectUser.addSomeoneElseForm.emailAddressLabel
                }}</label>
            <c11n-input
                size="medium"
                [hasError]="emailFormControl.invalid && emailFormControl.touched">
                <input
                    c11nInput
                    type="email"
                    inputmode="email"
                    id="email"
                    name="email"
                    class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8"
                    [formControlName]="emailControlName"
                    (blur)="trackFormattingErrorOnEmailField()"/>
            </c11n-input>
            <c11n-hint-error
                [hasError]="emailFormControl.touched && emailFormControl.invalid"
                [errorText]="getEmailAddressErrorMessage"
                size="medium"
            ></c11n-hint-error>
        </div>

        <div class="c11n-space-stack-4x">
            <label c11n-label [for]="phoneNumberControlName" size="medium">{{
                    content?.addUserFlow.mvp.selectUser.addSomeoneElseForm.phoneNumberLabel
                }}</label>
            <c11n-input
                size="medium"
                [hasError]="phoneNumberFormControl.invalid && phoneNumberFormControl.touched">
                <input
                    c11nInput
                    type="tel"
                    inputmode="tel"
                    id="phoneNumber"
                    name="phoneNumber"
                    class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4"
                    [imask]="InputMask.PHONE"
                    [formControlName]="phoneNumberControlName"
                    (blur)="trackFormattingErrorOnPhoneNumberField()"/>
            </c11n-input>
            <c11n-hint-error
                [hasError]="phoneNumberFormControl.touched && phoneNumberFormControl.invalid"
                [errorText]="getPhoneNumberErrorMessage"
                size="medium"
            ></c11n-hint-error>
        </div>
    </fieldset>
</form>
