import {
    Component,
    Directive,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    RadioComponent,
    RadioGroupComponent,
    RadioInputComponent,
} from '@vg-constellation/angular-16/radio';
import { LinkComponent } from '@vg-constellation/angular-16/link';
import { Observable, Subscription } from 'rxjs';
import { ContentUtil } from '../../../../utils/content/content.util';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AsyncPipe } from '@angular/common';
import { DrawerComponent } from '@vg-constellation/angular-16/drawer';
import { BreakpointObserverService } from '../../../../services/breakpoint-observer/breakpoint-observer.service';
import { AdobeTagging } from '../../../../utils/decorators/dev/adobe-launch';
import { getAdobeConfigData } from './grantee-regulatory-questions-adobe';
import { Store } from '@ngrx/store';
import { selectGranteeIneligibleReason } from 'src/app/store/selectors/grantee.selector';
import { GranteeIneligibleReasons } from 'src/common/enums/ineligible-scenarios.enum';
import {
    ModalDialogComponent,
    ModalDialogEntrypointComponent,
} from '@vg-constellation/angular-16/modal-dialog';
import { environment } from 'src/environments/environment';
import { ineligibleScenariosForGranteeInGranteeFlow } from 'src/app/store/actions/ineligible-scenarios.action';
import { ErrorService } from 'src/app/services/generic-error/error.service';
import { ErrorPage } from 'src/app/services/generic-error/error-page-utils';

@Directive()
class GranteeRegulatoryQuestionsComponent implements OnInit, OnDestroy {
    @ViewChild('controlQuestionsDrawer', { static: false }) drawerComponent!: DrawerComponent;
    @ViewChild('granteeRegulatoryForeignAddressModal', { static: false })
    granteeRegulatoryForeignAddressModal!: ModalDialogComponent;

    @Input() nextStepClicked: EventEmitter<any> = new EventEmitter<any>();
    @Input() nextStepReady: EventEmitter<any> = new EventEmitter<any>();

    @HostBinding('class.control-questions-drawer-open')
    drawerOpen: boolean = false;

    readonly subs: Subscription = new Subscription();
    granteeFAQDrawerContent = ContentUtil.granteeFragmentsContent.faqgrFrequentlyAskedQuestions;
    granteeRegulatoryQuestionContent =
        ContentUtil.granteeFragmentsContent.frmcGranteeFlow.frmcRegulatoryQuestions;

    granteeRegulatoryForeignAddressModalContent =
        ContentUtil.granteeFragmentsContent.infmForeignAddress;

    vanguardEmployeeControlName: string = 'vanguardEmployee';
    associatedPersonControlName: string = 'associatedPerson';
    controlPersonControlName: string = 'controlPerson';

    isMobileView$: Observable<boolean> = this.breakpointObserver.isMobileView$;

    isTabletView$: Observable<boolean> = this.breakpointObserver.isTabletView$;

    controlQuestionFormGroup: FormGroup = new FormGroup({
        [this.vanguardEmployeeControlName]: new FormControl(null, [Validators.required]),
        [this.associatedPersonControlName]: new FormControl(null, [Validators.required]),
        [this.controlPersonControlName]: new FormControl(null, [Validators.required]),
    });

    constructor(
        private readonly breakpointObserver: BreakpointObserverService,
        private errorService: ErrorService,
        private store: Store,
    ) {}

    get vanguardEmployee() {
        return this.controlQuestionFormGroup.get(this.vanguardEmployeeControlName);
    }

    get associatedPerson() {
        return this.controlQuestionFormGroup.get(this.associatedPersonControlName);
    }

    get controlPerson() {
        return this.controlQuestionFormGroup.get(this.controlPersonControlName);
    }

    ngOnInit(): void {
        this.subs.add(this.nextStepClicked.subscribe(this.onNextStepLoad.bind(this)));
        this.store.dispatch(ineligibleScenariosForGranteeInGranteeFlow());
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    private onNextStepLoad(): void {
        this.controlQuestionFormGroup.markAllAsTouched();

        this.store.select(selectGranteeIneligibleReason).subscribe((reason) => {
            if (reason === GranteeIneligibleReasons.FOREIGN_ADDRESS) {
                this.openForeignAddressModal();
                this.nextStepReady.emit(false);
            } else {
                if (this.isControlOrAssociatedPerson()) {
                    this.errorService.showError(ErrorPage.GRANTEE_CANT_COMPLETE_ONLINE);
                } else {
                    this.nextStepReady.emit(this.controlQuestionFormGroup.valid);
                }
            }
        });
    }

    openForeignAddressModal(): void {
        this.granteeRegulatoryForeignAddressModal.openModalDialog();
    }

    navigateToForm(): void {
        window.open(environment.AGENT_AUTHORIZATION_FORM_URL, '_self');
    }

    private isControlOrAssociatedPerson(): boolean {
        return (
            this.vanguardEmployee.value === '1' ||
            this.associatedPerson.value === '1' ||
            this.controlPerson.value === '1'
        );
    }

    toggleDrawer(): void {
        if (this.drawerOpen) {
            this.closeFaqDrawer();
        } else {
            this.openFaqDrawer();
        }
    }

    onDrawerStateChange(event: any): void {
        this.drawerOpen = event.isDrawerOpen;
    }

    private openFaqDrawer(): void {
        this.drawerComponent.openDrawer();
    }

    private closeFaqDrawer(): void {
        this.drawerComponent.closeDrawer();
    }
}

@AdobeTagging(getAdobeConfigData('existing'))
@Component({
    selector: 'zci-existing-grantee-regulatory-questions',
    templateUrl: 'grantee-regulatory-questions.component.html',
    styleUrls: ['./grantee-regulatory-questions.component.scss'],
    standalone: true,
    imports: [
        LinkComponent,
        RadioInputComponent,
        RadioComponent,
        RadioGroupComponent,
        ReactiveFormsModule,
        AsyncPipe,
        DrawerComponent,
        ModalDialogComponent,
        ModalDialogEntrypointComponent,
    ],
})
export class ExistingGranteeRegulatoryQuestionsComponent extends GranteeRegulatoryQuestionsComponent {}

@AdobeTagging(getAdobeConfigData('new'))
@Component({
    selector: 'zci-new-grantee-regulatory-questions',
    templateUrl: 'grantee-regulatory-questions.component.html',
    styleUrls: ['./grantee-regulatory-questions.component.scss'],
    standalone: true,
    imports: [
        LinkComponent,
        RadioInputComponent,
        RadioComponent,
        RadioGroupComponent,
        ReactiveFormsModule,
        AsyncPipe,
        DrawerComponent,
        ModalDialogComponent,
        ModalDialogEntrypointComponent,
    ],
})
export class NewGranteeRegulatoryQuestionsComponent extends GranteeRegulatoryQuestionsComponent {}
