import { Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ContentUtil } from '../../../../utils/content/content.util';
import { map, Subscription } from 'rxjs';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { CONSTANTS } from '../../../../../common/constants/constants';
import { environment } from 'src/environments/environment';
import { ModalDialogComponent } from '@vg-constellation/angular-16/modal-dialog';
import {
    PERMISSION_REQUEST_ERRORS,
    PinValidationService,
} from '../../../../services/pin-validation/pin-validation.service';
import { Store } from '@ngrx/store';
import { GranteeIneligibleReasons } from '../../../../../common/enums/ineligible-scenarios.enum';
import { selectGranteeIneligibleReason } from '../../../../store/selectors/grantee.selector';
import { ActivatedRoute } from '@angular/router';
import { TOKEN_PARAM } from '../../../../constants/application-routes';
import { ErrorService } from '../../../../services/generic-error/error.service';
import { errorPageMap } from '../../../../services/generic-error/error-page-utils';

@Component({
    selector: 'zci-grantee-pin-input',
    templateUrl: 'grantee-pin-input.component.html',
    styleUrls: ['grantee-pin-input.component.scss'],
})
export class GranteePinInputComponent implements OnInit, OnDestroy {
    @Input() nextStepClicked: EventEmitter<any> = new EventEmitter<any>();
    @Input() nextStepReady: EventEmitter<boolean> = new EventEmitter<boolean>();

    granteePinContent = ContentUtil.granteeFragmentsContent.frmcGranteeFlow.frmcUniquePin;

    granteePinForeignAddressModalContent = ContentUtil.granteeFragmentsContent.infmForeignAddress;

    pinFormControlName: string = 'pin';

    pinForm = new FormGroup({
        [this.pinFormControlName]: new FormControl(null, [
            Validators.pattern(CONSTANTS.UNIQUE_PIN_VALIDATION_PATTERN),
            Validators.required,
        ]),
    });

    pinValue: string;

    tokenValue: string;

    @ViewChild('granteePinForeignAddressModal', { static: false })
    granteePinForeignAddressModal!: ModalDialogComponent;

    readonly subs: Subscription = new Subscription();

    constructor(
        private store: Store,
        private pinValidationService: PinValidationService,
        private route: ActivatedRoute,
        private errorService: ErrorService,
    ) {}

    ngOnInit(): void {
        this.subs.add(this.nextStepClicked.subscribe(this.onNextStepLoad.bind(this)));
        this.onPageLoad();
        this.route.paramMap
            .pipe(
                map((params) => {
                    this.tokenValue = params.get(TOKEN_PARAM);
                }),
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    private onNextStepLoad(): void {
        this.pinValue = this.pinForm.get(this.pinFormControlName).value;
        this.pinForm.markAllAsTouched();

        if (!this.pinForm.valid) {
            this.nextStepReady.emit(false);
        }

        this.store.select(selectGranteeIneligibleReason).subscribe((reason) => {
            if (reason === GranteeIneligibleReasons.FOREIGN_ADDRESS) {
                this.granteePinForeignAddressModal.openModalDialog();
                this.nextStepReady.emit(false);
            } else {
                this.pinValidationService
                    .validatePin(this.pinValue, this.tokenValue)
                    .subscribe((response) => {
                        if (response.error === PERMISSION_REQUEST_ERRORS.PIN_IS_INCORRECT) {
                            this.pinFormControl.setErrors({ pattern: true });
                            this.nextStepReady.emit(false);
                            return;
                        }

                        if (Object.hasOwn(errorPageMap, response.error)) {
                            this.errorService.showError(errorPageMap[response.error]);
                            this.nextStepReady.emit(false);
                            return;
                        }

                        this.nextStepReady.emit(true);
                    });
            }
        });
    }

    private onPageLoad(): void {
        this.store.select(selectGranteeIneligibleReason).subscribe((reason) => {
            if (reason === GranteeIneligibleReasons.ASSOCIATED_OR_CONTROL_PERSON) {
                this.nextStepReady.emit(false);
            }
        });
    }

    navigateToForm() {
        window.open(environment.AGENT_AUTHORIZATION_FORM_URL, '_self');
    }

    get pinFormControl(): AbstractControl {
        return this.pinForm.get(this.pinFormControlName);
    }

    get getPinErrorMessage(): string | undefined {
        if (this.pinFormControl.hasError('required')) {
            return this.granteePinContent.frmfUniquePin.errEnterPin;
        }

        if (this.pinFormControl.hasError('pattern')) {
            return this.granteePinContent.frmfUniquePin.errEnterValidPin;
        }
        return undefined;
    }
}
