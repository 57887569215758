import { Deployment } from '../constants';
import { environmentGeneric } from './environment.generic';

// Stryker disable all
export const development = {
    ...environmentGeneric,
    development: true,
    isProduction: false,
    ADOBE_LAUNCH_ENV: 'test',
    ACCOUNT_SETTINGS_URL: 'https://satpersonal1.vanguard.com/mnt-profile-account-settings/',
    CHECK_LOGON:
        'https://webservices.ecs.rett.c1.vanguard.com/lgo-common-logon-logoff-ecs-webservice/logon',
    GRANTOR_LOGON_URL:
        'https://satlogon.vanguard.com?TARGET_OVERRIDE=https://access-permissions.webt.vanguard.com/my-accounts/',
    GRANTEE_INPUT_PIN_URL:
        'https://satlogon.vanguard.com?TARGET_OVERRIDE=https://access-permissions.webt.vanguard.com/authenticate-pin',
    GRANTEE_CONTROL_QUESTION_URL:
        'https://satlogon.vanguard.com?TARGET_OVERRIDE=https://access-permissions.webt.vanguard.com/regulatory-questions/',
    ZCI_API_BASE_URL: 'https://webservices.ecs.rett.c1.vanguard.com/zci-account-permission-center',
    // ZCI_API_BASE_URL: 'https://localhost.vanguard.com:8082',
    AWS: {
        SECRET_ARN:
            'arn:aws:secretsmanager:us-east-1:843844221290:secret:AWS_ZCI_OAuthCAG_ZCI_DEV-s3huFd',
        ADID_SECRET_ARN:
            'arn:aws:secretsmanager:us-east-1:843844221290:secret:ADS_ZCI_FileNetSAT-HFQbWj',
    },
    LEGACY_FLOW_URL: 'https://satpersonal.vanguard.com:55443/us/legals/ViewAccess',
    AEM_GRAPHQL: {
        BASE_URL: 'https://pi.aemgraphqltest.vanguard.com/graphql/execute.json',
        CONF_NAME: '/access-permissions',
        GRANTOR_CONTENT_QUERY_NAME: '/access_permissions',
        GRANTEE_CONTENT_QUERY_NAME: '/grantee-flow',
    },
    AGENT_AUTHORIZATION_FORM_URL:
        'https://satpersonal1.vanguard.com/eel-esign-summary-webapp/?interviewId=38a19d98-cd32-43e4-8dd8-398982298e67&ELF_ENTRY_POINT=true',
    ACCOUNT_BALANCES_URL: 'https://satpersonal.vanguard.com:port/us/MyHome',
    C3_URL: 'https://mc3-static-assets.webt.vanguard.com/vg-ccc-serverless/latest/vg-ccc-serverless.js',
    C3_CONFIG_DEPLOYMENT: Deployment.LOCAL,
    TEALEAF_URL: 'https://satpersonal1.vanguard.com/zci-account-permission-center/tealeaf',
    APP_DYNAMICS_KEY: 'AD-AAB-ACY-DBE',
    DASHBOARD_URL: 'https://dashboard.webt.vanguard.com',
};
