import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';
import { AccessTypeSelection } from '../../../../../common/enums/access-type-selection.enum';
import { selectGranteeAccessType } from '../../../../store/selectors/grantee.selector';
import { FlowKey } from '../../../../services/flow-config-service/flow-config.service';

@Component({
    selector: 'zci-new-grantee-landing-page',
    templateUrl: './new-grantee-landing-page.component.html',
})
export class NewGranteeLandingPageComponent implements OnInit {
    // TODO If there is an error, redirect to error page
    // TODO rename this page to grantee-landing-page and remove the existing-grantee-landing-page

    accessLevel$: Observable<AccessTypeSelection> = this.store.select(selectGranteeAccessType);

    flowKey: FlowKey;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly store: Store,
    ) {}

    ngOnInit(): void {
        this.route.data
            .pipe(
                tap((data) => {
                    // TODO FIX THIS AGAIN
                    this.flowKey = data.validateToken.isExistingClient
                        ? 'EXISTING_GRANTEE'
                        : data.validateToken?.accessLevel === AccessTypeSelection.INFORMATION_ONLY
                          ? 'NEW_GRANTEE_INFO_ONLY'
                          : 'NEW_GRANTEE_LIMITED';
                }),
            )
            .subscribe();
    }
}
