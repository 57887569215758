 <div class=" c11n-space-stack-6x c11n-space--force"></div>
    <h1 class="c11n-text-2xl-headline c11n-space-stack-2x">
        {{ granteePinContent.heading}}
    </h1>
    <p class="c11n-text-md c11n-space-stack-4x">
        {{ granteePinContent.subheading}}
    </p>
    <div class="input-width  c11n-space-stack-10x c11n-space--force">
        <form [formGroup]="pinForm" class="form">
        <label class="c11n-label c11n-label--medium" for="text-input-pin">
            <span class="c11n-label__content c11n-text-xs">
                {{granteePinContent.frmfUniquePin.labelText}}
            </span>
        </label>
        <div class="c11n-input c11n-input--medium col-xl-3 col-12">
            <input type="text" id="text-input-pin" name="input-pin"
                   class="c11n-input__input"
                   aria-describedby="text-input-pin-description"
                   [formControlName]="pinFormControlName"
            >
            <c11n-hint-error
                [hasError]="pinFormControl.touched && pinFormControl.invalid"
                [hintText]="granteePinContent.frmfUniquePin.hintText"
                [errorText]="getPinErrorMessage"
                size="medium">
            </c11n-hint-error>
        </div>
            </form>
        <c11n-modal-dialog-entrypoint></c11n-modal-dialog-entrypoint>
        <c11n-modal-dialog
            [variant]="'basic'"
            [primaryButtonLabel]="granteePinForeignAddressModalContent.navContinue"
            [secondaryButtonLabel]="granteePinForeignAddressModalContent.navBack"
            [headingText]="granteePinForeignAddressModalContent.heading"
            [hideBody]="true"
            (primaryClick)="navigateToForm()"
            [maxWidth]="660"
            [useContentWidth]="false"
        #granteePinForeignAddressModal>
        </c11n-modal-dialog>
</div>
