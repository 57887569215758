export const environmentGeneric = {
    APP_PREFIX: 'ZCI',
    ZCI_API_ENDPOINTS: {
        GET_ACCOUNT_USERS: '/api/account-permissions/accounts-users',
        GATEKEEPER: '/gatekeeper',
        SEARCH_CLIENT: '/api/account-permissions/search-client',
        GRANT_PERMISSION: '/api/access-permissions/grant-permission',
        REMOVE_PERMISSION: '/api/access-permissions/remove-permission',
        INELIGIBLE_SCENARIOS_GRANTOR: '/api/ineligible-scenarios/grantor',
        INELIGIBLE_SCENARIOS_GRANTEE: '/api/ineligible-scenarios/grantee',
        INELIGIBLE_SCENARIOS_GRANTEE_IN_GRANTEE_FLOW:
            '/api/ineligible-scenarios/grantee/in-grantee-flow',
        INELIGIBLE_SCENARIOS_ACCOUNTS: '/api/ineligible-scenarios/accounts',
        ADD_SOMEONE_ELSE: '/api/add-someone-else/add-new-grantee',
        AUTHENTICATE_PIN: '/api/access-permissions/authenticate-pin',
        VALIDATE_TOKEN: '/api/access-permissions/validate-token',
        CHECK_PENDING_REQUEST: '/api/access-permissions/check-pending-request',
    },
    ASSETS: {
        GLOBAL_NAV_TAXONOMY: {
            PATH: 'assets/global-nav/',
            FILE: 'global-nav-taxonomy-fallback.full.json',
        },
    },
    sessionTtlMilliseconds: 900000,
    SCOPES: ['oob', 'client-center-access', 'cdx_write', 'cdx_read', 'pit:clients:system:read'],
};
