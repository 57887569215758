<section
    class="complete-our-form-section col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9"
    *ngIf="true">
    <p class="c11n-text-sm">
        <strong>{{ content.addUserFlow.step2.completeOurForm.title }}</strong>
    </p>
    <p class="c11n-text-sm">
        {{ content.addUserFlow.step2.completeOurForm.preText
        }}<a c11n-link [href]="environment.AGENT_AUTHORIZATION_FORM_URL"
            >{{ content.addUserFlow.step2.completeOurForm.anchorText }} </a
        >{{ content.addUserFlow.step2.completeOurForm.postText }}
    </p>
</section>
