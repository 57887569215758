import { Component, Directive, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccessTypeSelection } from '../../../../../common/enums/access-type-selection.enum';
import { AdobeLaunchService } from '../../../../services/adobe-launch/adobe-launch.service';
import { AsyncPipe, NgIf } from '@angular/common';
import { ContentUtil } from '../../../../utils/content/content.util';
import { getAdobeConfigData } from './grantee-authorization-agreement-adobe';
import { AdobeTagging } from '../../../../utils/decorators/dev/adobe-launch';

@Directive()
class GranteeAuthorizationAgreementComponent implements OnInit, OnDestroy {
    readonly accessTypeSelection = AccessTypeSelection;

    @Input() nextStepClicked: EventEmitter<any> = new EventEmitter<any>();
    @Input() nextStepReady: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() locationChanged: EventEmitter<any>;

    granteeAuthorizationAgreement = ContentUtil.granteeFragmentsContent.trmAuthorizationAgreement;

    private subs: Subscription = new Subscription();

    constructor(protected adobeLaunchService: AdobeLaunchService) {}

    ngOnInit(): void {
        this.subs.add(this.nextStepClicked.subscribe(this.onNextStepLoad.bind(this)));
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    private onNextStepLoad(): void {
        this.nextStepReady.emit(true);
    }
}

@AdobeTagging(getAdobeConfigData('existing'))
@Component({
    selector: 'zci-existing-grantee-authorization-agreement',
    templateUrl: 'grantee-authorization-agreement.component.html',
    styleUrls: ['grantee-authorization-agreement.component.scss'],
    standalone: true,
    imports: [NgIf, AsyncPipe],
})
export class ExistingGranteeAuthorizationAgreementComponent extends GranteeAuthorizationAgreementComponent {}

@AdobeTagging(getAdobeConfigData('new'))
@Component({
    selector: 'zci-new-grantee-authorization-agreement',
    templateUrl: 'grantee-authorization-agreement.component.html',
    styleUrls: ['grantee-authorization-agreement.component.scss'],
    standalone: true,
    imports: [NgIf, AsyncPipe],
})
export class NewGranteeAuthorizationAgreementComponent extends GranteeAuthorizationAgreementComponent {}
