<p class="c11n-text-xl-headline">{{ content?.addUserFlow.mvp.triage.pageTitle }}</p>
<div class="justify-content-center negative-margin col-12 col-sm-9 col-md-9 col-lg-8 col-xl-8 col-xxl-8">
    <zci-triage-mvp-tile
        tabindex="0"
        [title]="content.addUserFlow.mvp.triage.existingGrantee.title"
        (click)="navigateSelectUser()">
    </zci-triage-mvp-tile>
    <zci-triage-mvp-tile
        tabindex="0"
        [title]="content.addUserFlow.mvp.triage.newGrantee.title"
        [description]="content.addUserFlow.mvp.triage.newGrantee.description"
        (click)="navigateAddSomeoneElse()">
    </zci-triage-mvp-tile>
    <zci-triage-mvp-tile
        tabindex="0"
        [title]="content.addUserFlow.mvp.triage.nonVgGrantee.title"
        [description]="content.addUserFlow.step1.nonVgGrantee.description"
        (click)="navigateToFormFromNonVGUser()">
    </zci-triage-mvp-tile>
    <zci-triage-mvp-tile
        tabindex="0"
        [title]="content.addUserFlow.mvp.triage.orgTrust.title"
        [description]="content.addUserFlow.mvp.triage.orgTrust.description"
        (click)="navigateToFormFromOrgTrust()">
    </zci-triage-mvp-tile>
</div>
