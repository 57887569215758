import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatestWith, map, Observable, Subscription } from 'rxjs';

import { APPLICATION_ROUTES } from './constants/application-routes';
import { ZciCookieService } from './services/zci-cookie/zci-cookie.service';
import { loadAccountUsers } from './store/actions/account-permissions-page.action';
import { selectAccountPermissionFlowApiErrorState } from './store/selectors/account-permissions.selector';
import {
    selectGrantRevokePermissionFlowApiErrorState,
    selectGrantRevokePermissionHttpErrorState,
} from './store/selectors/grant-revoke-permissions.selector';

@Component({
    selector: 'zci-root',
    templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
    hasError$: Observable<boolean> = this.accountPermissionApiErrorState().pipe(
        combineLatestWith(
            this.grantRevokePermissionApiErrorState(),
            this.grantRevokePermissionApiHttpErrorState(),
        ),
        map(([accError, grantError, jdiHttpError]) => accError || grantError || jdiHttpError),
    );

    accountPermissionApiErrorState(): Observable<boolean> {
        return this.store.select(selectAccountPermissionFlowApiErrorState).pipe(
            map((state) => {
                return !!state;
            }),
        );
    }

    grantRevokePermissionApiErrorState(): Observable<boolean> {
        return this.store.select(selectGrantRevokePermissionFlowApiErrorState).pipe(
            map((state) => {
                return !!state;
            }),
        );
    }

    grantRevokePermissionApiHttpErrorState(): Observable<boolean> {
        return this.store.select(selectGrantRevokePermissionHttpErrorState).pipe(
            map((state) => {
                return !!state;
            }),
        );
    }

    readonly subs: Subscription = new Subscription();

    constructor(
        private readonly store: Store,
        private router: Router,
        private cookieService: ZciCookieService,
    ) {}

    ngOnInit(): void {
        this.store.dispatch(loadAccountUsers());

        // @ts-expect-error spoid is not defined in window
        window.spoid = this.cookieService.sPOID;

        this.subs.add(
            this.hasError$.subscribe((hasError) => {
                if (hasError) {
                    this.router.navigate([APPLICATION_ROUTES.TECH_DIFF_REDIRECT]);
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
