<div class="container">
    <div class="d-flex justify-content-between col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10 mx-auto c11n-space-stack-1x c11n-space--force">
        <div
            class="flow-caption space-between c11n-text-sm"
            [ngClass]="{'no-contact-us': !buttons.contactUs}">
            {{flowConfig.flowCaption}}
        </div>
        <div
            class="d-flex spaced-buttons"
            [ngClass]="{'no-contact-us': !buttons.contactUs}">
            <button *ngIf="buttons.contactUs" class="d-flex contact-us-button" (click)="contactUs()" data-cy="contact-us">
                <c11n-icon
                    class="contact-us-button__icon"
                    name="chat-internal"
                    size="small"
                    altText="Contact us icon"></c11n-icon>
                <span class="contact-us-button__label">{{ addUserFlowGrantorContent.navContactUs.labelText }} </span>
            </button>
            <c11n-button
                *ngIf="buttons.quit"
                buttonType="button"
                size="small"
                variant="ghost"
                [iconName]="'remove'"
                [labelText]=addUserFlowGrantorContent.navQuit
                [iconPosition]="'trailing'"
                (clickEvent)="openModalDialog()"
                data-cy="quit-button">
            </c11n-button>
        </div>
    </div>
</div>

<c11n-modal-dialog-entrypoint></c11n-modal-dialog-entrypoint>
<c11n-modal-dialog
    [variant]="'warning'"
    [primaryButtonLabel]=addUserFlowGrantorContent.wrnQuitUnsaved.navYes
    [secondaryButtonLabel]=addUserFlowGrantorContent.wrnQuitUnsaved.navNo
    [headingText]=addUserFlowGrantorContent.wrnQuitUnsaved.heading
    [hideBody]="true"
    (primaryClick)="quit()"
    (secondaryClick)="stayOnCurrentStep()"
    [maxWidth]="660"
    [useContentWidth]="false"
    data-cy="error-modal"
    #quitButtonModalDialog>
</c11n-modal-dialog>

<div class="container c11n-space-stack-6x" *ngIf="isStepIndicatorNeeded">
    <div class="d-flex step-container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10 mx-auto">
        <ng-container >
            <h2
                class="c11n-text-sm-bold c11n-space-stack-1x">
                Step {{currentStep + 1}} of {{stepLabels.length}}
            </h2>
            <c11n-step-indicator
                [stepAlign]="'start'"
                [stepLabelDisplay]="(isMobileView$ | async) ? 'none' : 'all'"
                [stepLabels]="stepLabels"
                [currentStep]="currentStep + 1"></c11n-step-indicator>
        </ng-container>
        <c11n-details
            class="expandable-list"
            *ngIf="(isMobileView$ | async) && isStepIndicatorNeeded"
            [variant]="'secondary'"
            [expandLabelText]=addUserFlowGrantorContent.navAddUserStepperViewSteps
            [collapseLabelText]=addUserFlowGrantorContent.navAddUserStepperViewSteps
        >
            <ol class="c11n-expandable-list">
                <li *ngFor="let step of stepLabels; index as i;"
                    [attr.aria-current]="i === currentStep ?  'step' : null">
                    {{ step }}
                </li>
            </ol>
        </c11n-details>
    </div>
</div>

<div class="container">
    <div class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10 mx-auto">
        <ng-template *ngComponentOutlet="currentStepConfig.component; inputs: currentStepConfig.inputs" />
    </div>
</div>

<div class="button-wrapper">
    <div class="container">
        <div class="button-container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10 mx-auto">
            <c11n-button
                *ngIf="currentStepConfig.secondaryButton.length"
                buttonType="button"
                size="medium"
                variant="secondary"
                [labelText]="currentStepConfig.secondaryButton"
                [fullWidth]="isMobileView$ | async"
                (clickEvent)="backButton()"
                data-cy="stepper-back-button"></c11n-button>
            <c11n-button
                *ngIf="currentStepConfig.primaryButton"
                buttonType="button"
                size="medium"
                variant="primary"
                [labelText]="currentStepConfig.primaryButton"
                [isWaiting]="isWaiting$ | async"
                [fullWidth]="isMobileView$ | async"
                (clickEvent)="nextButton()"
                data-cy="stepper-next-button"></c11n-button>
        </div>
    </div>
</div>
<aside
    c11n-drawer
    class="gaf-drawer"
    [isModal]="isTabletView$ | async"
    [drawerWidth]="(isMobileView$ | async) ? '100%' : '480px'"
    (drawerState)="onDrawerStateChange($event)"
    #gafDrawer
    data-cy="contact-us-drawer">
    <ng-container>
        <zci-advisor
            [name]="title"
            [phoneNumber]="contactPhone"
            class="representative-card__details">
            <p class="c11n-text-xl-headline c11n-space-stack-6x drawer-title">
                {{  addUserFlowGrantorContent.navContactUs.hlpContactUs.heading }}
            </p>
        </zci-advisor>
    </ng-container>
</aside>

