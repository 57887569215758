import { Component, Directive, HostListener } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { BreakpointObserverService } from '../../../../services/breakpoint-observer/breakpoint-observer.service';
import { AccessTypeSelection } from '../../../../../common/enums/access-type-selection.enum';
import { ContentUtil } from '../../../../utils/content/content.util';
import { environment } from '../../../../../environments/environment';
import { selectGranteeAccessType } from '../../../../store/selectors/grantee.selector';
import { getAdobeConfigData } from './grantee-success-page-adobe';
import { AdobeTagging } from '../../../../utils/decorators/dev/adobe-launch';

@Directive()
class GranteeSuccessPageComponent {
    readonly accessTypeSelection = AccessTypeSelection;

    accessLevel$: Observable<AccessTypeSelection> = this.store.select(selectGranteeAccessType);

    forceSurveyZeroHeight: boolean = true;

    @HostListener('window:MDigital_Form_Displayed')
    removeForcedHeightZero() {
        this.forceSurveyZeroHeight = false;
    }

    isMobileView$: Observable<boolean> = this.breakpointObserverService.isMobileView$;

    dashboardUrl: string = environment.DASHBOARD_URL;

    granteeSuccessPageContentInfoOnly =
        ContentUtil.granteeFragmentsContent.frmcGranteeFlow.scssInformationOnlyAccess;
    granteeSuccessPageContentLimitedOnly =
        ContentUtil.granteeFragmentsContent.frmcGranteeFlow.scssLimitedAuthority;

    constructor(
        private readonly store: Store,
        private readonly breakpointObserverService: BreakpointObserverService,
    ) {}

    goToDashboard(): void {}
}

@AdobeTagging(getAdobeConfigData('existing'))
@Component({
    selector: 'zci-existing-grantee-success-page',
    templateUrl: './grantee-success-page.component.html',
    styleUrls: ['grantee-success-page.component.scss'],
})
export class ExistingGranteeSuccessPageComponent extends GranteeSuccessPageComponent {}

@AdobeTagging(getAdobeConfigData('new'))
@Component({
    selector: 'zci-new-grantee-success-page',
    templateUrl: './grantee-success-page.component.html',
    styleUrls: ['grantee-success-page.component.scss'],
})
export class NewGranteeSuccessPageComponent extends GranteeSuccessPageComponent {}
