import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable, Subject, Subscription, take, tap } from 'rxjs';
import { ACCOUNT_ID_PARAM, APPLICATION_ROUTES } from 'src/app/constants/application-routes';
import { MapAccountTypePipe } from 'src/app/pipes/map-account-type/map-account-type.pipe';
import { AdobePageName } from 'src/app/services/adobe-launch/adobe-launch.pages';
import { AdobeLaunchService } from 'src/app/services/adobe-launch/adobe-launch.service';
import { OwnAccountsService } from 'src/app/services/own-accounts/own-accounts.service';
import { PaginationProp, PaginatorService } from 'src/app/services/paginator/paginator.service';
import { TitleService } from 'src/app/services/title/title.service';
import { updateSelectedViewTypeForMyAccounts } from 'src/app/store/actions/view-type.action';
import {
    getSelectedViewTypeForMyAccounts,
    selectApiCallError,
    selectGrantorIsIneligibleFor,
    selectOwnAccounts,
} from 'src/app/store/selectors/account-permissions.selector';
import { CONSTANTS } from 'src/common/constants/constants';
import { OwnAccountDTO } from 'src/common/dtos/own-account.dto';

import { IneligibleReasons } from '../../../../common/enums/ineligible-scenarios.enum';
import { ViewType } from '../../../interfaces/view-type.enum';
import { ContentUtil } from '../../../utils/content/content.util';
import contentUtils from '../../../utils/content/content-format.util';
import { Sort, SortDirection } from '../../../utils/sort/sort.interfaces';
import { OwnAccountTileInputDataProps, SortService } from '../../../utils/sort/sort.service';
import sortUtil from '../../../utils/sort/sort.util';
import { TableColumn } from '../../table/table.interface';
import { TabComponent } from '../tab.component';
import {
    getOwnAccountsTableCols,
    OwnAccountsTableProps,
    OwnAccountsTableRow,
} from './own-accounts-table.config';

@Component({
    selector: 'zci-own-accounts',
    templateUrl: './own-accounts.component.html',
    providers: [PaginatorService],
})
export class OwnAccountsComponent extends TabComponent implements OnInit, OnDestroy {
    content = ContentUtil.content;

    accsPaginated: PaginationProp<OwnAccountTileInputDataProps>;

    accsTableRows$: Observable<OwnAccountsTableRow[]>;

    accsTableCols: TableColumn[] = getOwnAccountsTableCols(
        this.content.home.ownAccountsTable.headers,
    );

    private readonly mapAccountTypePipe: MapAccountTypePipe;

    private readonly ownAccountsService: OwnAccountsService;

    private readonly sortService: SortService;

    private readonly adobeLaunchService: AdobeLaunchService;

    private readonly subTitle: string = this.content.home.accountsNavTab.myAccountsItemTitle;

    private destroy$: Subject<void> = new Subject();

    grantorIsIneligibleFor$: Observable<Array<IneligibleReasons>> = this.store.select(
        selectGrantorIsIneligibleFor,
    );

    hasIneligibleError$: Observable<boolean> = this.store.select(selectApiCallError);

    hasIneligibleError: boolean = true;

    subs = new Subscription();

    constructor(
        private readonly paginatorService: PaginatorService,
        private readonly store: Store,
        private titleService: TitleService,
        private injector: Injector,
    ) {
        super(injector);
        this.mapAccountTypePipe = this.injector.get<MapAccountTypePipe>(MapAccountTypePipe);
        this.ownAccountsService = this.injector.get<OwnAccountsService>(OwnAccountsService);
        this.adobeLaunchService = this.injector.get<AdobeLaunchService>(AdobeLaunchService);
        this.sortService = this.injector.get<SortService>(SortService);
    }

    get loadMoreAccountsLabel$(): Observable<string> {
        return this.accsPaginated.nextCount$.pipe(
            map((count: number) => {
                return contentUtils.getContentWithDynamicNumber(
                    this.content.home.loadMoreAccounts,
                    count,
                );
            }),
        );
    }

    get destroy(): Subject<void> {
        return this.destroy$;
    }

    ngOnInit(): void {
        this.titleService.setTitle(this.subTitle);
        this.subs.add(this.checkIfIneligibleCallFailed());
        const ownAccounts$: Observable<OwnAccountTileInputDataProps[]> = this.store
            .select(selectOwnAccounts)
            .pipe(
                map((accs) => this.mapOwnAccounts(accs)),
                map((accs) => this.sortService.sortAccountsForCardView(accs)),
            );
        this.accsTableRows$ = ownAccounts$.pipe(map(this.mapToTableRows.bind(this)));

        const { hasMore$, isLoading$, nextCount$ } = this.paginatorService;
        const data$ = this.paginatorService.initPaginator<OwnAccountTileInputDataProps>(
            ownAccounts$,
            CONSTANTS.PAGINATOR_PAGE_SIZE,
        );
        this.accsPaginated = {
            data$,
            hasMore$,
            isLoading$,
            nextCount$,
            loadMore: () => this.paginatorService.loadMore(),
        };

        this.restoreViewTypeSelection();
        this.subscribeOnBreakpointChange();
    }

    mapOwnAccounts(data: OwnAccountDTO[] = []): OwnAccountTileInputDataProps[] {
        return data.map(this.mapOwnAccountForTile);
    }

    mapOwnAccountForTile(ownAccount: OwnAccountDTO): OwnAccountTileInputDataProps {
        return {
            accId: ownAccount.accountId,
            accName: ownAccount.accountName,
            agentsCount: ownAccount?.grantees?.length ?? 0,
            accType: ownAccount.accountType,
            servAgreeId: ownAccount.serviceAgreementId,
            accountIsIneligibleFor: ownAccount.isIneligible,
        };
    }

    checkIfIneligibleCallFailed(): Subscription {
        return this.hasIneligibleError$.pipe(take(1)).subscribe((didIneligibleCallFail) => {
            this.hasIneligibleError = didIneligibleCallFail;
        });
    }

    mapToTableRows(rows: OwnAccountTileInputDataProps[]): OwnAccountsTableRow[] {
        return rows.map((acct: OwnAccountTileInputDataProps) => {
            // TODO refactor - a quick fix for elevation, it's duplication of own-account-tile.component.ts ngOnChanges()
            // TODO this whole account ineligibility check should happen in effects or services, not components
            let updatedIneligibleFor = [...acct.accountIsIneligibleFor];

            updatedIneligibleFor = updatedIneligibleFor.sort();

            if (updatedIneligibleFor.includes(IneligibleReasons.MANAGED_ACCOUNT)) {
                updatedIneligibleFor.pop();
            }

            const ineligibleReason = updatedIneligibleFor[updatedIneligibleFor.length - 1];

            return {
                [OwnAccountsTableProps.UsrCount]: {
                    url: APPLICATION_ROUTES.USERS_ON_ACCOUNT.CHILD_PATH.replace(
                        `:${ACCOUNT_ID_PARAM}`,
                        acct.accId,
                    ),
                    usrCount: acct.agentsCount,
                },
                [OwnAccountsTableProps.AccName]: acct.accName,
                [OwnAccountsTableProps.AccType]: this.mapAccountTypePipe.transform(acct.accType),
                [OwnAccountsTableProps.Actions]: this.ownAccountsService.getNavOverflowConfig(
                    acct,
                    ineligibleReason,
                    this.hasIneligibleError,
                ),
            };
        });
    }

    getRowPosition({
        acc1,
        acc2,
        sortBy,
        sortDir,
    }: {
        acc1: OwnAccountsTableRow;
        acc2: OwnAccountsTableRow;
        sortBy: string;
        sortDir: SortDirection;
    }): 1 | -1 | 0 {
        if (sortBy === OwnAccountsTableProps.UsrCount) {
            return sortUtil.getPositionBySortDirection({
                current: acc1[sortBy].usrCount,
                next: acc2[sortBy].usrCount,
                sortDir,
            });
        }

        return sortUtil.getPositionBySortDirection({
            current: acc1[sortBy],
            next: acc2[sortBy],
            sortDir,
        });
    }

    onColumnSort(event: Sort): void {
        const sortBy: string = event.dataKey;
        const sortDir = event.sortDir;

        this.accsTableRows$ = this.accsTableRows$.pipe(
            map((accs: OwnAccountsTableRow[]) =>
                accs.sort((acc1: OwnAccountsTableRow, acc2: OwnAccountsTableRow) =>
                    this.getRowPosition({ acc1, acc2, sortBy, sortDir }),
                ),
            ),
        );
    }

    protected saveViewTypeSelection(): void {
        this.store.dispatch(updateSelectedViewTypeForMyAccounts(this.viewType));
    }

    protected restoreViewTypeSelection(): void {
        this.store
            .select(getSelectedViewTypeForMyAccounts)
            .pipe(take(1), tap(this.trackPageLoad))
            .subscribe((viewType) => {
                this.viewType = viewType;
                this.changeDetector.detectChanges();
            });
    }

    private trackPageLoad = (viewType: ViewType): void => {
        const pageName: AdobePageName = this.adobeLaunchService.isAdobeInitialized
            ? AdobePageName.OWN_ACCOUNTS_PAGE
            : AdobePageName.OWN_ACCOUNTS_HOME_PAGE;

        this.adobeLaunchService.pageLoad(pageName, this.getViewTypeForPageLoad(viewType));
    };

    ngOnDestroy(): void {
        this.subs.unsubscribe();
        this.saveViewTypeSelection();
        this.destroy$.next();
        this.destroy$.complete();
    }
}
