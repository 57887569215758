import { catchError, firstValueFrom, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { APPLICATION_ROUTES } from './constants/application-routes';

const successful = 'SUCCESSFUL';

interface CheckLogonResponse {
    state: string;
}

export function initializeApp(http: HttpClient, window: Window) {
    // TODO this could be done better
    if (window.document.location.href.includes(APPLICATION_ROUTES.GENERIC_ERROR)) {
        window.document.location.href = window.document.location.href.replace(
            APPLICATION_ROUTES.GENERIC_ERROR,
            APPLICATION_ROUTES.OWN_ACCOUNTS,
        );
        return () => Promise.resolve();
    }

    return (): Promise<void> =>
        firstValueFrom(
            http.get(environment.CHECK_LOGON).pipe(
                map((response: CheckLogonResponse) => {
                    if (response.state === successful) {
                        window.document.body.hidden = false;
                    } else {
                        throw new Error('User is not logged in, redirecting to logon page');
                    }
                }),
                catchError(() => {
                    if (
                        window.document.location.href.includes(
                            APPLICATION_ROUTES.REGULATORY_QUESTIONS,
                        )
                    ) {
                        // TODO this should be made dynamic,
                        //  we need to replace TARGET_OVERRIDE param
                        //  and NOT to store the whole URL for each case
                        window.document.location.href = environment.GRANTEE_CONTROL_QUESTION_URL;
                    } else if (
                        window.document.location.href.includes(APPLICATION_ROUTES.GRANTEE_PIN_INPUT)
                    ) {
                        window.document.location.href = environment.GRANTEE_INPUT_PIN_URL;
                    } else {
                        window.document.location.href = environment.GRANTOR_LOGON_URL;
                    }

                    return Promise.reject(); // Ensure that app initialization is aborted since we're going to the (external) logon page.
                }),
            ),
        );
}
