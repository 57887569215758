import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorContent } from '../../../services/generic-error/error.service';
import { environment } from '../../../../environments/environment.development-external';
import { VgnService } from '../../../services/vgn/vgn.service';
import { Observable } from 'rxjs';
import { BreakpointObserverService } from '../../../services/breakpoint-observer/breakpoint-observer.service';

@Component({
    selector: 'zci-generic-error',
    templateUrl: './generic-error.component.html',
    styleUrls: ['./generic-error.component.scss'],
})
export class GenericErrorComponent implements OnInit, OnDestroy {
    errorContent: ErrorContent;

    isMobileView$: Observable<boolean> = this.breakpointObserverService.isMobileView$;

    constructor(
        private vgnService: VgnService,
        private router: Router,
        private breakpointObserverService: BreakpointObserverService,
    ) {}

    ngOnInit() {
        this.errorContent = this.router.lastSuccessfulNavigation.extras?.state?.errorContent;

        this.vgnService.setMinimalNav(false);
    }

    ngOnDestroy() {
        this.vgnService.setMinimalNav(true);
    }

    protected readonly environment = environment;
}
