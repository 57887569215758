import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    GrantPermissionResponseDTO,
    RemovePermissionResponseDTO,
} from 'src/common/dtos/access-permissions.dto';
import { environment } from 'src/environments/environment';

import {
    GranteeDetailsInterface,
    GrantorDetailsInterface,
} from '../../../common/interfaces/access-permissions.interface';
@Injectable({
    providedIn: 'root',
})
export class AccessPermissionsService {
    constructor(private readonly httpClient: HttpClient) {}

    grantPermission(
        granteeDetails: GranteeDetailsInterface,
        grantorDetails: GrantorDetailsInterface,
        accountName: string,
    ): Observable<GrantPermissionResponseDTO> {
        return this.httpClient.post<GrantPermissionResponseDTO>(
            `${environment.ZCI_API_BASE_URL}${environment.ZCI_API_ENDPOINTS.GRANT_PERMISSION}`,
            {
                granteeDetails,
                grantorDetails,
                accountName,
            },
        );
    }

    removePermission(
        granteeDetails: GranteeDetailsInterface,
        grantorDetails: GrantorDetailsInterface,
    ): Observable<RemovePermissionResponseDTO> {
        return this.httpClient.post<GrantPermissionResponseDTO>(
            `${environment.ZCI_API_BASE_URL}${environment.ZCI_API_ENDPOINTS.REMOVE_PERMISSION}`,
            {
                granteeDetails,
                grantorDetails,
            },
        );
    }
}
