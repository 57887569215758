<div class="row d-flex justify-content-center stepper-top">
    <div
        [ngClass]="{
            'col-md-12 col-xl-10': currentPage === pagesOfAddUserToOneSelectedAccount.STEPPER,
            'col-md-10 col-xl-10': selectConfirmationNumber$ | async
        }">
        <ng-container *ngIf="((selectUniquePin$ | async) === null && (selectConfirmationNumber$ | async) === null)">
            <zci-stepper
                [flow]="'GAF'"
            ></zci-stepper>
        </ng-container>
        <ng-container *ngIf="((selectUniquePin$) | async) || (selectConfirmationNumber$ | async)">
            <div class="col-12 no-padding c11n-space--force survey-breakpoint-adjustment justify-content-xl-around">
                <div class="c11n-space-stack-6x margin-left survey-center-margin col-xl-4">
                    <zci-gaf-confirmation-page-mvp-component
                        [uniquePin]="selectUniquePin$ | async"></zci-gaf-confirmation-page-mvp-component>
                </div>
                <div class="container">
                    <div
                        class="d-flex col-10 col-xl-6 mx-auto survey-size-adjustment"
                        id="csat-embedded-survey"
                        [ngClass]="{ 'survey-height': forceSurveyZeroHeight }">
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
