<div class="container-xl">
    <div class="c11n-space-stack-10x"></div>
    <div class="align-items-center col-xl-6">
        <c11n-icon
            class="custom-green-icon"
            [name]="'success-fill'"
            size="medium"
            scale="larger"
            data-cy="add-another-person-confirmation-icon">
        </c11n-icon>

        <div
            *ngIf="(accessLevel$ | async) === accessTypeSelection.INFORMATION_ONLY">
            <div
                class="c11n-space-stack-6x c11n-space--force">
                <h1
                    class="c11n-text-2xl-headline c11n-space-stack-3x c11n-space--force c11n-space-inline-left-8x"
                    data-cy="page-title">
                    {{ granteeSuccessPageContentInfoOnly.heading }}
                </h1>
                <div
                    class="c11n-text-md c11n-space-stack-3x d-flex small-text-align justify-content-center c11n-space--force"
                    data-cy="page-title">
                    {{ granteeSuccessPageContentInfoOnly.subheading }}
                </div>
            </div>
        </div>

        <div *ngIf="(accessLevel$ | async) === accessTypeSelection.LIMITED_ACCESS">
            <div
                class="c11n-space-stack-6x c11n-space--force">
                <h1
                    class="c11n-text-2xl-headline c11n-space-stack-3x c11n-space--force c11n-space-inline-left-8x"
                    data-cy="page-title">
                    {{ granteeSuccessPageContentLimitedOnly.heading }}
                </h1>
                <p class="c11n-text-md c11n-space-stack-3x d-flex small-text-align justify-content-center"
                   data-cy="page-title">
                    {{ granteeSuccessPageContentLimitedOnly.subheading }}
                </p>
            </div>
        </div>

        <div class="button-wrapper c11n-space-stack-10x c11n-space--force">
            <c11n-button
                buttonType="button"
                size="medium"
                variant="primary"
                (clickEvent)="goToDashboard()"
                [labelText]="granteeSuccessPageContentInfoOnly.navViewMyDashboard"
                [fullWidth]="isMobileView$ | async"
                [href]="dashboardUrl"></c11n-button>
        </div>
    </div>
</div>
