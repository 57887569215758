import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of, tap, combineLatest } from 'rxjs';

import { ContentService } from '../services/content/content.service';
import { ContentUtil } from '../utils/content/content.util';

@Injectable({
    providedIn: 'root',
})
export class ContentResolver implements Resolve<any> {
    constructor(private readonly contentService: ContentService) {}

    resolve(): Observable<any> {
        if (ContentUtil.content && ContentUtil.granteeFragmentsContent && ContentUtil.grantorFragmentContent) {
            return of([ContentUtil.content, ContentUtil.granteeFragmentsContent, ContentUtil.grantorFragmentContent]);
        }

        return combineLatest([
            this.contentService.getContent(),
            this.contentService.getGranteeGraphqlContent(),
            this.contentService.getGrantorGraphqlContent(),
        ]).pipe(
            tap(([content, granteeFragmentsContent, grantorFragmentContent]) => {
                ContentUtil.content = content;

                ContentUtil.granteeFragmentsContent = granteeFragmentsContent;
                ContentUtil.grantorFragmentContent = grantorFragmentContent;
            }),
        );
    }
}
